/**
    1. General
    2. Scrollbar
    3. Pre-loader
**/

/* ===============================================
    1. General
------------------------*/
.main {
    height: 100vh;
    overflow: auto;
    flex-grow: 1;
}
a {
    text-decoration: none;
}
.breadcrumb {
    padding: 0 8px 8px 8px;
}
.MuiButton-root {
    text-transform: none !important;
}

/* ===============================================
    2. Scrollbar
------------------------*/
.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            width: 5px;
        }
    }
    .ps__thumb-y {
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}
.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

/* ===============================================
    3. Pre-loader
------------------------*/
.pre-loader {
    position: fixed;
    z-index: 1301;
    top: 0;
    left: 0;
    width: 100%;
    .linear-loader {
        z-index: 1301;
    }
    .circular-loader {
        position: fixed;
        z-index: 1300;
        color: #fff;
    }
}

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
